import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    organization: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    emailjs.init("Qwik_yIkCaCWxbQ30");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      // Execute reCAPTCHA with action
      const recaptchaToken = await window.grecaptcha.execute('YOUR_SITE_KEY', { action: 'submit_contact' });
      
      if (!recaptchaToken) {
        throw new Error('Could not verify human user');
      }

      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        organization: formData.organization,
        message: formData.message,
        'g-recaptcha-response': recaptchaToken
      };

      await emailjs.send(
        'service_zz3uzlh',
        'template_gqs7zqs',
        templateParams
      );

      setStatus({
        type: 'success',
        message: 'Thank you for your message. We will get back to you soon!'
      });
      
      setFormData({
        name: '',
        email: '',
        organization: '',
        message: ''
      });
    } catch (error) {
      console.error('Error:', error);
      setStatus({
        type: 'error',
        message: error.message || 'There was an error sending your message. Please try again later.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="contact" className="bg-white dark:bg-dark py-16 lg:py-24 scroll-mt-20">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              Get in Touch
            </h2>
            <p className="mt-4 text-lg text-gray-500 dark:text-gray-300">
              Interested in learning more about how Tuntau can help your organization?
              Fill out the form below and we'll get back to you as soon as possible.
            </p>
            <dl className="mt-8 text-base text-gray-500 dark:text-gray-300">
              <div className="mt-6">
                <dt className="sr-only">Location</dt>
                <dd className="flex">
                  <svg className="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span className="ml-3">Belgium</span>
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-12 lg:mt-0">
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6">
              {status.message && (
                <div className={`rounded-md p-4 ${
                  status.type === 'success' 
                    ? 'bg-green-50 dark:bg-green-900 text-green-700 dark:text-green-200' 
                    : 'bg-red-50 dark:bg-red-900 text-red-700 dark:text-red-200'
                }`}>
                  <p>{status.message}</p>
                </div>
              )}
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-tuntau-primary focus:border-tuntau-primary border-gray-300 dark:border-gray-600 rounded-md dark:bg-dark-surface dark:text-white"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-tuntau-primary focus:border-tuntau-primary border-gray-300 dark:border-gray-600 rounded-md dark:bg-dark-surface dark:text-white"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="organization" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Organization
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="organization"
                    id="organization"
                    value={formData.organization}
                    onChange={handleChange}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-tuntau-primary focus:border-tuntau-primary border-gray-300 dark:border-gray-600 rounded-md dark:bg-dark-surface dark:text-white"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Message
                </label>
                <div className="mt-1">
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    required
                    value={formData.message}
                    onChange={handleChange}
                    className="py-3 px-4 block w-full shadow-sm focus:ring-tuntau-primary focus:border-tuntau-primary border-gray-300 dark:border-gray-600 rounded-md dark:bg-dark-surface dark:text-white"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-tuntau-primary hover:bg-tuntau-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tuntau-primary transition-colors duration-200 ${
                    isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
