import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-[calc(100vh-16rem)] flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-max mx-auto text-center">
        <main>
          <p className="text-base font-semibold text-tuntau-primary">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">Sorry, we couldn't find the page you're looking for.</p>
          <div className="mt-10">
            <Link
              to="/"
              className="rounded-md bg-tuntau-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-tuntau-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-tuntau-primary"
            >
              Go back home
            </Link>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotFound;
