import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Sitemap from './components/Sitemap';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import NotFound from './components/NotFound';
import CookieConsent from './components/CookieConsent';

function HomePage() {
  return (
    <>
      <Hero />
      <Features />
      <About />
      <Contact />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-white dark:bg-dark">
        <Navbar />
        <main id="main-content" tabIndex="-1" className="pt-16 outline-none">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
}

export default App;
