import React from 'react';

const About = () => {
  return (
    <div id="about" className="bg-gray-50 dark:bg-dark-surface py-16 lg:py-24 scroll-mt-20">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
              About Tuntau
            </h2>
          </div>
          <div className="mt-6 lg:mt-0 lg:col-span-2">
            <div className="prose prose-indigo dark:prose-invert prose-lg text-gray-500 dark:text-gray-300">
              <p>
                Based in Belgium, Tuntau is committed to revolutionizing digital solutions
                for organizations facing hardware constraints. We understand the challenges
                that schools and other institutions face when it comes to maintaining
                modern IT infrastructure.
              </p>
              <p className="mt-4">
                Our flagship product, Hyperdesk, represents our vision for accessible,
                efficient, and cost-effective computing solutions. By leveraging cloud
                technology and optimized mobile support, we're making powerful computing
                capabilities available to everyone, regardless of their hardware limitations.
              </p>
              <p className="mt-4">
                We're particularly focused on helping educational institutions and organizations
                that need to maximize their IT resources without compromising on performance
                or user experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
