import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/');
  };

  return (
    <nav 
      className={`fixed w-full z-50 transition-all duration-200 ${
        hasScrolled 
          ? 'bg-white/95 dark:bg-dark-surface/95 shadow-lg' 
          : 'bg-white dark:bg-dark-surface'
      }`} 
      role="navigation" 
      aria-label="Main navigation"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center space-x-3">
              <div onClick={handleHomeClick} className="flex items-center space-x-3 cursor-pointer">
                <img
                  className="h-8 w-auto"
                  src="/icons/tuntau.svg"
                  alt="Tuntau"
                />
                <strong className="text-xl font-bold text-gray-900 dark:text-white">Tuntau</strong>
              </div>
            </div>
          </div>

          {/* Desktop menu */}
          <div className="hidden sm:flex sm:items-center">
            {/* Skip link integrated into the navigation */}
            <a
              href="#main-content"
              className="sr-only focus:not-sr-only focus:relative focus:inline-block focus:px-3 focus:py-2 focus:text-gray-700 dark:focus:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light focus:rounded-md focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2 text-sm font-medium"
              tabIndex="0"
            >
              Skip to main content
            </a>
            
            <div className="flex items-center space-x-8" role="list">
              <div role="listitem">
                <a
                  href="#features"
                  onClick={handleLinkClick}
                  className="text-gray-700 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2"
                  tabIndex="0"
                >
                  Features
                </a>
              </div>
              <div role="listitem">
                <a
                  href="#about"
                  onClick={handleLinkClick}
                  className="text-gray-700 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2"
                  tabIndex="0"
                >
                  About
                </a>
              </div>
              <div role="listitem">
                <a
                  href="#contact"
                  onClick={handleLinkClick}
                  className="text-gray-700 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light px-3 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2"
                  tabIndex="0"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
          
          {/* Mobile menu button */}
          <div className="flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-dark-surface-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tuntau-primary"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
              onClick={() => setIsOpen(!isOpen)}
              tabIndex="0"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`${isOpen ? 'block' : 'hidden'} sm:hidden ${
          hasScrolled 
            ? 'bg-white/95 dark:bg-dark-surface/95' 
            : 'bg-white dark:bg-dark-surface'
        }`}
        id="mobile-menu"
        role="menu"
        aria-orientation="vertical"
      >
        <div className="pt-2 pb-3 space-y-1">
          {/* Skip link in mobile menu */}
          <a
            href="#main-content"
            className="sr-only focus:not-sr-only focus:block focus:px-3 focus:py-2 focus:text-gray-700 dark:focus:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light focus:rounded-md focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2 text-base font-medium"
            tabIndex="0"
          >
            Skip to main content
          </a>
          <div role="listitem">
            <a
              href="#features"
              onClick={handleLinkClick}
              className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light hover:bg-gray-50 dark:hover:bg-dark-surface-light focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2"
              tabIndex="0"
            >
              Features
            </a>
          </div>
          <div role="listitem">
            <a
              href="#about"
              onClick={handleLinkClick}
              className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light hover:bg-gray-50 dark:hover:bg-dark-surface-light focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2"
              tabIndex="0"
            >
              About
            </a>
          </div>
          <div role="listitem">
            <a
              href="#contact"
              onClick={handleLinkClick}
              className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light hover:bg-gray-50 dark:hover:bg-dark-surface-light focus:outline-none focus:ring-2 focus:ring-tuntau-primary focus:ring-offset-2"
              tabIndex="0"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
