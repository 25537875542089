import React from 'react';

const Sitemap = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">Site Map</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Main Navigation</h2>
          <ul className="space-y-2">
            <li><a href="/" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">Home</a></li>
            <li><a href="/#features" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">Features</a></li>
            <li><a href="/#about" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">About</a></li>
            <li><a href="/#contact" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">Contact</a></li>
          </ul>
        </div>
        
        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Legal</h2>
          <ul className="space-y-2">
            <li><a href="/privacy" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">Privacy Policy</a></li>
            <li><a href="/terms" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">Terms of Service</a></li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Resources</h2>
          <ul className="space-y-2">
            <li><a href="/sitemap" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">HTML Sitemap</a></li>
            <li><a href="/sitemap.xml" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">XML Sitemap</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
