import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleHomeClick = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/');
  };

  return (
    <footer className="bg-white dark:bg-dark" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-8">
            <div 
              onClick={handleHomeClick}
              className="cursor-pointer flex items-center space-x-3"
              role="link"
              tabIndex="0"
              onKeyDown={(e) => e.key === 'Enter' && handleHomeClick(e)}
            >
              <img
                className="h-10"
                src="/icons/tuntau.svg"
                alt="Tuntau"
              />
              <strong className="text-xl font-bold text-gray-900 dark:text-white">Tuntau</strong>
            </div>
            <p className="text-gray-600 dark:text-gray-300 text-base">
              Making digital solutions accessible to everyone.
            </p>
          </div>

          {/* Product */}
          <div>
            <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-200 tracking-wider uppercase">Product</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <a
                  href="#features"
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light"
                  tabIndex="0"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light"
                  tabIndex="0"
                >
                  About
                </a>
              </li>
            </ul>
          </div>

          {/* Support */}
          <div>
            <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-200 tracking-wider uppercase">Support</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <a
                  href="#contact"
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light"
                  tabIndex="0"
                >
                  Contact
                </a>
              </li>
              <li>
                <Link
                  to="/sitemap"
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light"
                  tabIndex="0"
                >
                  Sitemap
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-200 tracking-wider uppercase">Legal</h3>
            <ul role="list" className="mt-4 space-y-4">
              <li>
                <Link
                  to="/terms"
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light"
                  tabIndex="0"
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy"
                  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  className="text-base text-gray-600 dark:text-gray-300 hover:text-tuntau-primary dark:hover:text-tuntau-primary-light"
                  tabIndex="0"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 border-t border-gray-200 dark:border-gray-700 pt-8">
          <p className="text-base text-gray-500 dark:text-gray-400 text-center">
            &copy; {currentYear} Tuntau. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
