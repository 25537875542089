import React from 'react';

const Hero = () => {
  return (
    <section className="critical-hero relative bg-white dark:bg-dark overflow-hidden h-[calc(100vh-4rem)] flex items-center">
      <img className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[80vw] h-full opacity-30 object-contain object-center" src="/icons/banner-back.svg" alt="Banner Background" />
      <img className="absolute bottom-0 left-0 w-full max-w-[20vw]  opacity-30 object-contain object-center" src="/icons/banner-stripes.svg" alt="Banner stripes background" />
      <img className="absolute top-0 right-0 -rotate-180 w-full max-w-[20vw]  opacity-30 object-contain object-center" src="/icons/banner-stripes.svg" alt="Banner stripes background" />
      {/* Critical content first */}
      <div className="relative z-10 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center">
        <div className="sm:text-center lg:text-left p-8 rounded-lg lg:max-w-4xl">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl">
            <span className="block xl:inline text-[0.8em]">Introducing</span>{' '}
            <span className="block text-tuntau-primary dark:text-tuntau-primary-light xl:inline text-[1.2em]">Hyperdesk</span>
          </h1>
          <p className="mt-3 text-base text-gray-800 dark:text-gray-100 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 lg:max-w-3xl">
            Experience the next generation of remote desktop solutions. Hyperdesk offers superior mobile support and helps organizations save on expensive hardware upgrades.
          </p>
          <div className="mt-8 sm:flex sm:justify-start lg:justify-end">
            <div className="rounded-md shadow">
              <button
                disabled
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-600 bg-gray-200 dark:bg-gray-800 dark:text-gray-300 cursor-not-allowed md:py-4 md:text-lg md:px-10"
                aria-disabled="true"
                tabIndex="0"
              >
                Coming Soon
              </button>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <a
                href="#features"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md bg-tuntau-primary text-white hover:bg-tuntau-primary-dark dark:bg-tuntau-primary-light dark:text-gray-900 dark:hover:bg-tuntau-primary md:py-4 md:text-lg md:px-10 transition-colors duration-200 focus:outline-none focus-visible:ring-4 focus-visible:ring-tuntau-primary focus-visible:ring-opacity-50 focus-visible:ring-offset-2"
                aria-label="Explore Hyperdesk Features"
                tabIndex="0"
              >
                Explore Hyperdesk Features
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
