import React from 'react';

const Privacy = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">Privacy Policy</h1>
      
      <div className="prose dark:prose-invert max-w-none">
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Cookie Policy</h2>
          <p className="mb-4">
            Our website uses cookies to enhance your browsing experience and provide essential functionality. 
            Below is a detailed explanation of the cookies we use:
          </p>
          
          <h3 className="text-xl font-semibold mb-3">1. Necessary Cookies (Required)</h3>
          <p className="mb-4">
            These cookies are essential for the website to function properly and cannot be disabled:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>RECAPTCHA_SITE_KEY</strong>: Used by Google reCAPTCHA to protect our forms from spam</li>
            <li><strong>cookieConsent</strong>: Stores your cookie preferences</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">2. Functional Cookies (Optional)</h3>
          <p className="mb-4">
            These cookies enable enhanced functionality and personalization:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>theme</strong>: Remembers your dark/light mode preference</li>
            <li><strong>language</strong>: Stores your preferred language setting</li>
          </ul>

          <h3 className="text-xl font-semibold mb-3">3. Analytics Cookies (Optional)</h3>
          <p className="mb-4">
            These cookies help us understand how visitors interact with our website:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>_ga, _gid, _gat</strong>: Google Analytics cookies that collect information about how visitors use our site</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Information Collection</h2>
          <p>
            We collect information that you provide directly to us, including when you:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Fill out forms on our website</li>
            <li>Correspond with us</li>
            <li>Sign up for our newsletter</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
          <p>We use the information we collect to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide and maintain our services</li>
            <li>Respond to your requests and inquiries</li>
            <li>Improve our website and services</li>
            <li>Send you technical notices and updates</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect your personal information
            against unauthorized access, alteration, disclosure, or destruction.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Third-Party Services</h2>
          <p>
            We use the following third-party services:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Google reCAPTCHA</strong>: For form protection against spam</li>
            <li><strong>Google Analytics</strong>: For website usage analysis</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Access your personal information</li>
            <li>Correct inaccurate information</li>
            <li>Request deletion of your information</li>
            <li>Object to processing of your information</li>
            <li>Withdraw consent</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us through our website's contact form.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
