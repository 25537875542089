import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const COOKIE_TYPES = {
  necessary: {
    name: 'Necessary',
    description: 'Essential cookies that enable basic website functionality and security features. These cannot be disabled.',
    required: true,
    cookies: ['RECAPTCHA_SITE_KEY', 'cookieConsent']
  },
  functional: {
    name: 'Functional',
    description: 'Cookies that enhance website functionality and personalization.',
    required: false,
    cookies: ['theme', 'language']
  },
  analytics: {
    name: 'Analytics',
    description: 'Cookies that help us understand how visitors interact with our website.',
    required: false,
    cookies: ['_ga', '_gid', '_gat']
  }
};

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true, // Always true
    functional: false,
    analytics: false
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent) {
      try {
        const savedPreferences = JSON.parse(consent);
        setPreferences(prev => ({
          ...prev,
          ...savedPreferences,
          necessary: true // Always ensure necessary cookies are enabled
        }));
      } catch (e) {
        setIsVisible(true);
      }
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    const allEnabled = {
      necessary: true,
      functional: true,
      analytics: true
    };
    localStorage.setItem('cookieConsent', JSON.stringify(allEnabled));
    setPreferences(allEnabled);
    setIsVisible(false);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setIsVisible(false);
  };

  const togglePreference = (type) => {
    if (COOKIE_TYPES[type].required) return;
    setPreferences(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-dark-surface shadow-lg z-50 border-t border-gray-200 dark:border-gray-700">
      <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
        <div className="flex flex-col gap-4">
          <div className="text-sm text-gray-700 dark:text-gray-300">
            <p className="mb-2">
              We use cookies to enhance your browsing experience. Some cookies are essential for the website to function properly.
              See our <Link to="/privacy" className="text-tuntau-primary hover:text-tuntau-primary-dark dark:text-tuntau-primary-light">
                Privacy Policy
              </Link> for more information.
            </p>
          </div>
          
          {showDetails && (
            <div className="space-y-4 border-t border-gray-200 dark:border-gray-700 pt-4">
              {Object.entries(COOKIE_TYPES).map(([type, info]) => (
                <div key={type} className="flex items-start gap-4">
                  <div className="pt-1">
                    <input
                      type="checkbox"
                      id={`cookie-${type}`}
                      checked={preferences[type]}
                      onChange={() => togglePreference(type)}
                      disabled={info.required}
                      className="h-4 w-4 rounded border-gray-300 text-tuntau-primary focus:ring-tuntau-primary"
                    />
                  </div>
                  <div>
                    <label htmlFor={`cookie-${type}`} className="font-medium text-gray-900 dark:text-white">
                      {info.name} {info.required && <span className="text-sm text-gray-500">(Required)</span>}
                    </label>
                    <p className="text-sm text-gray-500 dark:text-gray-400">{info.description}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          
          <div className="flex flex-col sm:flex-row justify-end items-center gap-4">
            <button
              onClick={() => setShowDetails(!showDetails)}
              className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white underline"
            >
              {showDetails ? 'Hide preferences' : 'Cookie preferences'}
            </button>
            <div className="flex gap-4">
              <button
                onClick={handleSavePreferences}
                className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white border border-gray-300 dark:border-gray-600 rounded-md"
              >
                Save preferences
              </button>
              <button
                onClick={handleAcceptAll}
                className="px-4 py-2 text-sm font-medium text-white bg-tuntau-primary hover:bg-tuntau-primary-dark dark:bg-tuntau-primary-light dark:text-gray-900 rounded-md transition-colors duration-200"
              >
                Accept all
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
